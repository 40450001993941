import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";

export class MobileAuthVerifyErrorHandler extends BaseErrorHandlerFactory {

  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, reject: any): any {
    if (err?.name === "StatusError") {
      const responseObj = err.context;
      if (responseObj?.status === "RETRY") {
        //otpMessage.value = response['message'];
        this.context?.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID,{}, {}, this.aaErrorContext(responseObj));
        reject(ErrorMessages.OTP_VALIDATION_FAILED);
      } else if (responseObj?.status === "BAD_REQUEST") {
        this.context?.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID,{}, {}, this.aaErrorContext(responseObj));
        reject(ErrorMessages.OTP_VALIDATION_FAILED);
        this.handleBadRequest(ErrorCodes.DATA_INVALID, ErrorTypes.INPUT_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else if (responseObj?.status === "FAILURE") {
        this.context?.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_INVALID, {}, {}, this.aaErrorContext(responseObj));
        reject(ErrorMessages.OTP_VALIDATION_FAILED);
      } else if (responseObj?.status === "SESSION_ERROR") {
        this.handleSessionError();
      } else {
        this.context?.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VERIFY_FAILED,{}, {}, this.aaErrorContext(responseObj));
        reject();
      }
    } else if (err === ErrorMessages.SESSION_EXPIRED) {
      this.context.fireJourneyEvents(ConductorEvent.ALT_MOBILE_AA_OTP_VERIFY_FAILED);
      reject(err);
    }
  }
}