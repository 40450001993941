
import { defineComponent, onMounted, ref, watch, computed } from 'vue';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption, TransitionRoot, TransitionChild, Dialog, DialogPanel } from '@headlessui/vue';
import useBackend from '@/services/api.service';
import ErrorBlock from '@/components/Error.vue';
import { ConductorEvent } from '@/conductor/journey-constants';
import { eventPayload } from '@/conductor/conductor';
import { storeToRefs } from 'pinia';
import { useOrgInfosTemplate } from '@/store';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import CardItem from '@/components/Card.vue';
import { XCircleIcon, CheckCircleIcon, ExclamationCircleIcon, ChevronDownIcon, XIcon } from '@heroicons/vue/outline';

import useEventService from '@/services/events.service';
import Image from '@/components/V2/Image2.vue';

interface WebviewDetails {
  consentHandle: string;
  redirectUrl: string;
  webviewBaseUrl: string;
  parameters: {
    fi: string;
    ecreq: string;
    reqdate: string;
  };
}

interface ViewError {
  status?: boolean;
  code?: string;
  message?: string;
  errCode?: string;
}

export default defineComponent({
  props: ['identifier'],
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,

    ErrorBlock,
    Header,
    Footer,
    CardItem,
    ChevronDownIcon,
    XCircleIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    XIcon,
    Image
  },

  setup(props) {
    const { getWebviewDetails, } = useBackend();
    const events = useEventService();
    // collect store variables
    const { fipType, mobileNumber, aaList, aaSelected, tenantId, 
      orgName, brandLogo, brandColor, 
      loading, error, 
      aaFipList, fipListMap } = storeToRefs(useOrgInfosTemplate());
    // collect pinia store actions
    const { /*orgInfoFipList,*/ orgInfos, orgFips, discriminator } = useOrgInfosTemplate();
    const pageProcessing = ref(false);
    const requestProcessing = ref(false);
        
    const selectedMap = computed(() => {
      return fipListMap.value[aaSelected.value.handle] || [];
    })

    // On mount, load info for the requested identifier
    onMounted(async () => {
      pageProcessing.value = true;
      await orgInfos(props.identifier)
        .then(async () => {
          if ((tenantId.value === 'balic' || tenantId.value === '1finance') && aaList.value.length === 1) {
            // let's push automatically to the next screen
            await processRequest();
          }
          await orgFips(props.identifier);
        }).finally(() => {
          if (tenantId.value === 'balic' || tenantId.value === '1finance') { // special case
            setTimeout(() => {
              pageProcessing.value = false;
            }, 1000); // reset the page processing
          } else {
            pageProcessing.value = false;
          }
        });
      //firing OPEN event on page load and after tenant is known
      events.fire(ConductorEvent.OPEN, eventPayload(props.identifier, discriminator));
    });

    const viewError = ref<ViewError>({});
    const processingError = ref<ViewError>({});

    watch(error, async (newError: any) => {
      if (newError.status === false) {
        return; // don't process the error update
      }

      viewError.value.status = newError.status;
      // Set the error code, if available
      if (newError?.code) {
        viewError.value.code = newError.code;
      }
      // Set the error message, if available
      // if (newError?.message) {
      //   viewError.value.message = newError?.message;
      // }
    });

    function handleWebviewDetails(details: WebviewDetails) {
      // Send user on the consent journey
      window.location.href = details.redirectUrl;
      //window.open(details.redirectUrl,'_blank');
    }

    function cleanAaHandle(aa: any) {
      const aaHandle = aa.handle;
      if(aaHandle && aaHandle.startsWith('@')) {
        return aaHandle.replace('@', '');
      }
      return aaHandle;
    }

    function getAaHandle(aa: any) {
      const aaHandle = aa.handle;
      if(aaHandle && !aaHandle.startsWith('@')) {
        return '@' + aaHandle;
      }
      return aaHandle;
    }

    const imgPattern = `^https[^]*.(${process.env.VUE_APP_IMG_EXT_LIST})((.*))?$`

    function checkAaLogo(url: any) {
      if(typeof url !== 'string') return false;
      return(url.match(imgPattern) != null);
    }

    async function processRequest() {
      requestProcessing.value = true;

      const aaHandleInfo: any = {aaHandle: cleanAaHandle(aaSelected.value)}
      await events.fire(ConductorEvent.AA_SELECTED, eventPayload(props.identifier, discriminator, {
        aaHandle: aaHandleInfo.aaHandle,
        autoSelection: false
      }));
      return getWebviewDetails(props.identifier, aaHandleInfo)
        .then((response: any) => {
          handleWebviewDetails(response.data)
        })
        .catch((e: any) => {
          if (e.response) {
            // Request made and server responded
            const errResponse = e.response;
            processingError.value = {
              status: true,
              code: errResponse.status.toString(),
              errCode: e.response.data.errorCode
              // message: e.response.data.errorMessage
            };
          } else if (e.request) {
            // The request was made but no response was received
            processingError.value = {
              status: true,
              code: '500',
              message: 'Unable to process the request'
            };
          } else {
            // Something happened in setting up the request that triggered an Error
            processingError.value = {
              status: true,
              code: '500',
              message: e.message
            };
          }
        })
        .finally(() => {
          // set loading to false
          requestProcessing.value = false;
        });
    }

    const disabledContinueBtn = computed(() => {
      if (!mobileNumber) {
        return true;
      }
      return requestProcessing.value;
    });

    // PNG Tenant Modal

    const pnbTCDailog = ref(false);
    function pnbTCmodal() {
      pnbTCDailog.value = true;
    }

    const supportContactDetail = computed(() => {
      if (orgName.value) {
        return orgName.value;
      }

      return 'your service provider/agent';
    })
    
    return {
      tenantId,
      pageProcessing,
      requestProcessing,
      processRequest,
      fipType,
      mobileNumber,
      orgName,
      brandLogo,
      brandColor,
      supportContactDetail,
      loading,
      error,
      viewError,
      processingError,
      disabledContinueBtn,
      aaList,
      aaSelected,
      getAaHandle,
      checkAaLogo,
      aaFipList,
      fipListMap,
      selectedMap,

      pnbTCmodal,
      pnbTCDailog
    };
  }
});
