import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";
import { eventPayload } from "@/conductor/conductor";

export class AuthInitErrorHandler extends BaseErrorHandlerFactory {

  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, isRetry: boolean): any {
    if (err?.name === "StatusError") {
      const {
        discriminator,
        aaHandle
      } = this.storeToRefs;
      const responseObj = err.context;
      if (responseObj?.status === "RETRY") {
        this.log.debug(`Recoverable error while ${isRetry ? "Re-" : ""}sending OTP from Account Aggregator`, aaHandle.value);
        this.events.fire(ConductorEvent.AA_OTP_FAILED, eventPayload(this.store.requestId, discriminator.value, {
          aaHandle: aaHandle.value,
          retriable: true,
        }, this.aaErrorContext(responseObj)));
      } else if (responseObj?.status === "BAD_REQUEST") {
        this.events.fire(ConductorEvent.AA_OTP_FAILED, eventPayload(this.store.requestId, discriminator.value, {
          aaHandle: aaHandle.value,
          retriable: false,
        }, this.aaErrorContext(responseObj)));
        this.handleBadRequest(ErrorCodes.BAD_REQUEST, ErrorTypes.INPUT_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else if (responseObj?.status === "FAILURE") {
        //TODO : Switch AA
        this.events.fire(ConductorEvent.AA_OTP_FAILED, eventPayload(this.store.requestId, discriminator.value, {
          aaHandle: aaHandle.value,
          retriable: false,
        }, this.aaErrorContext(responseObj)));
        this.handleFailures(ErrorCodes.FAILURE, ErrorTypes.FLOW_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else if (responseObj?.status === "SESSION_ERROR") {
        this.handleSessionError();
      } else {
        this.handleFailures(ErrorCodes.UNKNOWN, ErrorTypes.FLOW_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      }
    } else if (err === ErrorMessages.SESSION_EXPIRED) {
      this.handleSessionError();
      this.log.fatal("Failed to " + (isRetry ? "Re-" : "") + "send OTP, some error occurred due to session or request timeout");
    } else {
      this._raiseErrorEventForAA("Failed to connect to Account Aggregator", "AA_UNREACHABLE");
      this.log.fatal("Failed to " + (isRetry ? "Re-" : "") + "send OTP, some error occurred due to session or request timeout");
    }
  }
}