import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";
import { eventPayload } from "../conductor";

export class UserInfoErrorHandler extends BaseErrorHandlerFactory {

  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any): any {
    if (err?.name === "StatusError") {
      const {
        discriminator,
        aaHandle
      } = this.storeToRefs;
      const responseObj = err.context;
      this.events.fire(ConductorEvent.USER_INFO_FETCH_FAILURE, eventPayload(this.store.requestId, discriminator.value, {
        aaHandle: aaHandle.value,
        retriable: true,
      }, this.aaErrorContext(responseObj)));
      if (responseObj?.status === "BAD_REQUEST") {
        this.handleBadRequest(ErrorCodes.DATA_INVALID, ErrorTypes.INPUT_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else {
        this.log.debug("Failed to get the user details from AA");
        this.handleBadRequest(ErrorCodes.FAILURE, ErrorTypes.FLOW_ERROR, "FAILURE", true, this.aaErrorContext(responseObj));
      }
    } else {
      if (err === ErrorMessages.SESSION_EXPIRED) {
        this.handleSessionError();
      }
      this.log.fatal("Error while trying to get user details from AA", err);
    }
  }
}