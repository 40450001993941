import { useOrgInfosTemplate, useV2ItrInfoStore } from "@/store";
import { usev2Store } from "@/store/v2.store";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { computed } from "vue";
import emitter from '@/composables/event.emitter';
import { useAAJourneyStore } from "@/store/aa-journey.store";

import FingerprintJS from '@fingerprintjs/fingerprintjs';

const eventEmitter  = emitter;
declare global {
  interface Window {
    ReactNativeWebView?: any; // React Native support
    AnubhavFlutter?: any; // Flutter support
    Android?: any; // for Android support
    webkit?: any; // For iOS support
    anubhavExchange?: Function
  }
}

window.anubhavExchange = (type: string, payload?: string) => {
  console.log("[exchange] type: '" + type + "', payload: " + payload);
  try {
    if (payload && typeof payload === 'string') {
      eventEmitter.emit(type, JSON.parse(payload || ''));
    } else {
      throw new Error('Payload invalid, please check');
    }
  } catch (error) {
    console.log('some error occured on event emitter', error);
  }
}

type Listener = (type: string, payload: Record<string, any>) => void;

function getDeviceType() {
  try {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/android/.test(userAgent)) {
      return "android";
    } else if (/iphone|ipod/.test(userAgent)) {
      return "ios";
    } else if (
      /ipad/.test(userAgent) ||
      ("platform" in navigator &&
        navigator["platform"] === "MacIntel" &&
        navigator.maxTouchPoints > 1)
    ) {
      return "ios"; // iPad detection
    }
    return "web";
  } catch (err) {
    console.error("Error while resolving device type ", err);
    return "unknown";
  }
}

const listeners: Listener[] = [];
const device = getDeviceType();
let app = 'native';
if (window.ReactNativeWebView) {
  app = 'react-native';
  listeners.push((t, p) => {
    try {
      const message = JSON.stringify({
        type: t, 
        payload: p
      });
      window.ReactNativeWebView.postMessage(message);
    } catch(e) {
      console.error("[events/error] React Native events listener is not configured properly", e);
    }
  });
} else if (window.AnubhavFlutter) {
  app = 'flutter';
  listeners.push((t, p) => {
    try {
      const message = JSON.stringify({
        type: t, 
        payload: p
      });
      window.AnubhavFlutter.postMessage(message);
    } catch(e) {
      console.error("[events/error] Flutter events listener is not configured properly", e);
    }
  });
} else if (window.Android) {
  app = 'native';
  listeners.push((t, p) => {
    try {
      window.Android.on(t, JSON.stringify(p));
    } catch(e) {
      console.debug("[events/error] Android events listener is not configured properly", e);
    }
  });
} else if (window["webkit"]?.messageHandlers?.nativeApp) {
  app = 'native';
  // Check if iOS listener is available
  listeners.push((t, p) => {
    try {
      window["webkit"].messageHandlers.nativeApp.postMessage({
        type: t,
        payload: p
      });
    } catch(e) {
      console.error("[events/error] iOS events listener is not configured properly", e);
    }
  })
} else if (window["self"] !== window["top"]) { // Check if in an iframe
  app = 'iframe';
  listeners.push((t, p) => {
    try {
      const msg = {
        event: t,
        metadata: p
      };
      // Publish
      window.parent.postMessage(msg, "*"); // TODO: dynamically resolve the target origins
    } catch (e) {
      console.error("[events/error] iFrame events publisher is not configure properly", e);
    }
  });
  console.debug("[events.config] iFrame events publisher attached");
}

// Attach a console listener to check if events are getting generated properly
if (listeners.length === 0 && process.env.NODE_ENV !== 'production') {
    listeners.push((t, p) => {
        console.log(`[events] ${t}, payload: ${JSON.stringify(p)}`);
    })
    console.debug("[events.config] Using default, console logged attached")
}

const http: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_EVENTS_BASE,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
  withCredentials: false,
});

export default function useEventService() {
  const store1 = useOrgInfosTemplate();
  const store2 = usev2Store();
  const commonJourneyStore = useAAJourneyStore();
  const itrStore = useV2ItrInfoStore();
  const tenant = computed(() => {
    return store2.tenantId ? store2.tenantId : commonJourneyStore.tenantId ? commonJourneyStore.tenantId : store1.tenantId ? store1.tenantId : itrStore.tenantId;
  });

  const resolvedRequestId = computed(() => {
    return store2.reqId ? store2.reqId : commonJourneyStore.requestId ? commonJourneyStore.requestId  : itrStore.requestId;
  });

  const resolveDiscriminator =  commonJourneyStore.discriminator ? commonJourneyStore.discriminator  : Date.now();

  const resolvedLastKnownStatus = computed(() => {
    return  commonJourneyStore.lastKnownStatus ? commonJourneyStore.lastKnownStatus : '';
  });

  const _hubQueue = [] as any[];
  const TIMEOUT_DURATION = process.env.VUE_APP_HEARTBEAT_TIMEOUT_DURATION ? parseInt(process.env.VUE_APP_HEARTBEAT_TIMEOUT_DURATION) : 10000; // 10s

  let timeoutId: string | number | NodeJS.Timeout | null | undefined = null;
  let prevFiredEventTime = 0;
  let startEvents = false;

  const STOP_DURATION = process.env.VUE_APP_HEARTBEAT_STOP_DURATION ? parseInt(process.env.VUE_APP_HEARTBEAT_STOP_DURATION) : 1000*2*60; // 2 mins

  let heartBeatStartTime = 0;// start time from where the event has started

  let fpDone = false;
  const loadFpAndFire = async (requestId: string, discriminator?: number) => {
    // Load the FingerprintJS agent
    const fp = await FingerprintJS.load();
    const { visitorId, components, confidence, version }  = await fp.get();
    fireInternal("FP", { 
      requestId,
      timestamp: new Date().toISOString(),
      discriminator,
      visitorId,
      components,
      score: confidence.score,
      version
    });
    fpDone = true;
  }
  
  function fireInternal(type: string, payload: Record<string, any>) {
    fire(type, { ...payload, internal: true }, { internal: true });
  }
  
  function fire(type: string, payload: Record<string, any>, labels?: Record<string, any>) {
    try {
      const internal = labels?.internal ? labels.internal : false;
      if (!internal) {
        listeners.forEach(l => l(type, payload)); 
      }
    } catch(e) {
      console.warn("[events] observed an error while eventing to listeners", e);
    } finally {
      // finally, deliver to events hub
      processHubEvent(type, payload);
    }
  }

  function _prepHubData(tenant: string, type: string, payload: Record<string, any>) {
    const { requestId, timestamp, ...otherPayload } = payload;
    return {
      tenantId: tenant,
      workspaceId: tenant,
      requestId: requestId,
      event: type,
      eventTime: timestamp,
      payload: { ...otherPayload, device, app }
    };
  }

  async function _deliverToHub(data: any) {
    await http.post('/e/event', data)
      .then((response: AxiosResponse) => {
        const status = response.status;
        if (status === 200) {
          // Success, yay!
          console.log(`[events-hub] ${data.event} — ${response.data.id} delivered`);
          updateLastEventTime(); // checking whether an event was delivered in the last 10 seconds;
        } else {
          console.error(`[events-hub] ${data.event} delivery failed (status: ${status})`);
        }
      })
      .catch(e => {
        console.error(`[events-hub] ${data.event} delivery failed (message: ${e?.message ? e.message : 'na'})`);
      })
      .finally(() => {
        // do something here?
      });
  }

  function processHubEvent(type: string, payload: Record<string, any>) {
    // 1. check if tenant is resolved yet, if yes then start clearing the hubQueue
    if (!tenant.value) {
      _hubQueue.push({ type, payload });
      return;
    }

    if (!fpDone) {
      fpDone = true;
      loadFpAndFire(payload.requestId, payload?.discriminator);
    }

    // Now that tenant value is available, clear any pending items
    if (_hubQueue.length > 0) {
      while (_hubQueue.length !== 0) {
        // loop on pending items to deliver to hub
        const e = _hubQueue.splice(0, 1);
        if (e.length > 0) {
          _deliverToHub(_prepHubData(tenant.value, e[0].type, e[0].payload));
        }
      }
    }
    
    // deliver to hub directly
    _deliverToHub(_prepHubData(tenant.value, type, payload));
  }

  function updateLastEventTime() {
    prevFiredEventTime = Date.now();
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (startEvents) {
      timeoutId = setTimeout(checkAndFireDummyEvent, TIMEOUT_DURATION);
    }
  }

  function checkAndFireDummyEvent() {
    if (!startEvents) return;
    if (!monitorHeartBeatEvents()) {
      clearTimedEvents();
      return;
    }

    const currentTime = Date.now();
    console.log(
      "currentTime - prevFiredEventTime",
      currentTime - prevFiredEventTime
    );
    if (currentTime - prevFiredEventTime >= TIMEOUT_DURATION) {
      console.log(
        "No events fired in the last 10 seconds. Firing heartbeat event."
      );
      fire(
        "HEARTBEAT",
        {
          timestamp: new Date().toISOString(),
          requestId: resolvedRequestId.value,
          discriminator: resolveDiscriminator,
          lastStatus : resolvedLastKnownStatus.value
        },
        { internal: true }
      );
    } else if (startEvents || monitorHeartBeatEvents()) {
      timeoutId = setTimeout(() => checkAndFireDummyEvent(), TIMEOUT_DURATION);
    }
  }

  function canFireHeartbeatEvents(): void {
    startEvents = true;
    heartBeatStartTime = Date.now();
    updateLastEventTime();
    console.log("can fire heatbeat events");
  }

  function clearTimedEvents(): void {
    startEvents = false;
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
    heartBeatStartTime = 0;
    console.log("Event heartbeats have stopped");
  }

  function monitorHeartBeatEvents(){
    return (Date.now() - heartBeatStartTime) < STOP_DURATION;
  }

  return {
    fire,
    fireInternal,
    clearTimedEvents,
    canFireHeartbeatEvents
  };
}
