import { LinkedAccount } from "@finarkein/aasdk-core";
import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";
import { JOURNEY_LAYOUTS } from "@/constants/constants";

export class FetchLinkedAccountsErrorHandler extends BaseErrorHandlerFactory {

  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, layoutView: string, views: any, transitionToView: (view: any, layout?: JOURNEY_LAYOUTS) => any, updateAndGetDiscoveryQueue: (linkedAccounts?: LinkedAccount[]) => any): any {
    if (err?.name === "StatusError") {
      const responseObj = err.context;
      // Linked account fetch has failed probably!
      this.log.debug("Failed to get already linked accounts from AA");
      this.context?.fireJourneyEvents(ConductorEvent.ERROR, {}, // request details, if any
        {
          // general error information
          errorCode: ErrorCodes.FAILURE,
          errorType: ErrorTypes.FLOW_ERROR,
          errorMessage: "Failed to resolve linked accounts",
        }, this.aaErrorContext(responseObj), // aa error context
        { internal: true } // labels
      );
      if (layoutView === JOURNEY_LAYOUTS.V2) {
        transitionToView(views.discovery);
      }
      return updateAndGetDiscoveryQueue();
    } else {
      if (err === ErrorMessages.SESSION_EXPIRED) {
        this.handleSessionError();
        return new Set<string>();
      }
      this.log.error("Error while trying to get already linked accounts from AA", err);
      return updateAndGetDiscoveryQueue();
    }
  }
}