import { ConsentAction } from "@finarkein/aasdk-core";
import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, JourneyType } from "@/conductor/journey-constants";

export class ConsentActionErrorHandler extends BaseErrorHandlerFactory {

  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, context: {
    retryCount: number,
    retryLimitCount: number,
    consentHandleValue: any,
    action: ConsentAction,
    utils: any,
    increaseRetryCount: () => number;
  }): any {
    const {
      aaHandle,
      extraJourneyReq
    } = this.storeToRefs;
    const {
      retryLimitCount,
      consentHandleValue,
      action,
      utils,
      increaseRetryCount
    } = context;

    let { retryCount } = context;
    if (err?.name === "StatusError") {
      const responseObj = err.context;
      if (responseObj?.status === "RETRY" || responseObj?.status === "FAILURE") {
        retryCount = increaseRetryCount();
        if (retryCount > retryLimitCount) {
          if (this.storeToRefs.extraJourneyReq.value?.jType === JourneyType.AUTHORIZED_MULTI_CONSENT) {
            this.context?.fireJourneyEvents(ConductorEvent.CONSENT_FAILED, {}, {}, {
              consentAction: action,
              aaHandle: aaHandle.value,
              childRequestId: utils.getKeyFromValue(this.store.consentHandleMap, consentHandleValue),
            }, { internal: true });
          }
          throw responseObj;
        }
      } else {
        increaseRetryCount();
        if (extraJourneyReq.value?.jType === JourneyType.AUTHORIZED_MULTI_CONSENT) {
          this.context?.fireJourneyEvents(ConductorEvent.CONSENT_FAILED, {}, {}, {
            consentAction: action,
            aaHandle: aaHandle.value,
            childRequestId: utils.getKeyFromValue(this.store.consentHandleMap, consentHandleValue),
          }, { internal: true });
        }
        throw responseObj;
      }
    } else {
      if (retryCount >= retryLimitCount) {
        throw err;
      }
    }
  }
}