import { AaMeta } from "@/conductor/models";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useAAXJourneyStore = defineStore("journeyXStore", () => {
  // defining here all the variables for the Anubhav x
  const xid = ref("");
  const completionRedirectUrl = ref("");
  const alternateRedirectUrl = ref("");
  const reqId = ref("");
  const tId = ref("");
  // selected fip becomes the account filter for the further journey
  const selectedFip = ref([] as any);
  const fipList = ref([] as any);
  const brandInfo = ref({
    color: "",
    logo: "",
    name: "",
  });
  const availableAAs = ref(new Array<AaMeta>());
  const errorXValue = ref({} as any);
  const webRedirectUrl = ref();

  // variables that are extra & only required for v2
  
  // mainly configcat features - these are temp, move to featurelist later on
  const forTenantRedirectParams = ref();
  const showRedirectButton = ref();
  const redirectMode = ref();
  const customAAJourneyList = ref();
  const markCompleteStatus = ref({
    status: '',
    errorCode: '',
    userId: '',
    consentHandle: '',
    errorHandleTxt: ''
  });

  const isJourneyCancelled = ref(false);

  const isV2X = ref(false);

  const sessionError = ref(false);

  function setAaMeta(metas: Array<AaMeta>) {
    availableAAs.value.length = 0;
    metas.forEach((a) => availableAAs.value.push(a));
  }

  function updateXDetails(data: any) {
    setAaMeta(data.aa);
    xid.value = data.xid;
    completionRedirectUrl.value = data.onCompleteRedirectUrl;
    alternateRedirectUrl.value = data.altRedirectUrl;
    fipList.value = data.fips;
    tId.value = data.orgInfo.orgId;
    setAaMeta(data.aa);
    updatebrandInfo(data.orgInfo.brandName, data.orgInfo.brand.color, data.orgInfo.brand.logo);
  }

  function updatebrandInfo(name: string, color: string, logo: string) {
    brandInfo.value.name = name;
    brandInfo.value.color = color;
    brandInfo.value.logo = logo;
  }
  return {
    xid,
    completionRedirectUrl,
    alternateRedirectUrl,
    reqId,
    tId,
    availableAAs,
    brandInfo,
    fipList,
    selectedFip,
    updateXDetails,
    errorXValue,
    // configcat features, later on move to feature list just like for aa journey
    forTenantRedirectParams,
    showRedirectButton,
    redirectMode,
    customAAJourneyList, 
    webRedirectUrl,
    markCompleteStatus,
    isJourneyCancelled,
    isV2X,
    sessionError  };
});
