import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";

export class ConfirmAccountLinkingErrorHandler extends BaseErrorHandlerFactory {

  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, refNum: string, fipName: string): any {
    const { aaHandle } = this.storeToRefs;
    if (err?.name === "StatusError") {
      const responseObj = err.context;
      if (responseObj?.status === "BAD_REQUEST") {
        this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID,{}, {}, this.aaErrorContext(responseObj));
        this.handleBadRequest(ErrorCodes.BAD_REQUEST, ErrorTypes.INPUT_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else if (responseObj?.status === "FAILURE") {
        this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID,{}, {}, this.aaErrorContext(responseObj));
        this.handleBadRequest(ErrorCodes.FAILURE, ErrorTypes.FLOW_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else if (responseObj?.status === "RETRY") {
        this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_OTP_INVALID,{}, {}, this.aaErrorContext(responseObj));
        this.log.error(ErrorMessages.OTP_VALIDATION_FAILED);
        throw new Error(ErrorMessages.OTP_VALIDATION_FAILED);
      } else if (responseObj?.status === "SESSION_ERROR") {
        this.handleSessionError();
        throw new Error(ErrorMessages.SESSION_EXPIRED);
      } else {
        this.context?.fireJourneyEvents(ConductorEvent.ACCOUNTS_LINKING_FAILED,{}, {}, this.aaErrorContext(responseObj));
        this.handleBadRequest(ErrorCodes.UNKNOWN, ErrorTypes.INSTITUTION_ERROR, responseObj?.message, false, this.aaErrorContext(responseObj));
        this.log.error(`Error while confirm account linking using ref: '${refNum}' for ${fipName} with AA`, aaHandle.value);
      }
    } else {
      if (err === ErrorMessages.SESSION_EXPIRED) {
        this.handleSessionError();
      }
      this.log.fatal(`Error while confirm account linking using ref with unknown error: '${refNum}' for ${fipName} with AA`, aaHandle.value, err?.message | err);
      throw err;
    }
  }
}