import { BaseErrorHandlerFactory, HandlerContext } from "@/conductor/ErrorHandlers/BaseErrorHandlerFactory";
import { ConductorEvent, ErrorCodes, ErrorMessages, ErrorTypes } from "@/conductor/journey-constants";

export class AuthVerifyErrorHandler extends BaseErrorHandlerFactory {

  constructor(ctx: HandlerContext) {
    super(ctx);
  }

  processError(err: any, reject: any): any {
    if (err?.name === "StatusError") {
      const responseObj = err.context;
      if (responseObj?.status === "RETRY") {
        this.context?.fireJourneyEvents?.(ConductorEvent.AA_OTP_INVALID, {}, {}, this.aaErrorContext(responseObj));
        this.log.warn("Please retry again");
        reject?.("RETRY")
      } else if (responseObj?.status === "FAILURE") {
        this.context?.fireJourneyEvents?.(ConductorEvent.AA_OTP_INVALID, {}, {}, this.aaErrorContext(responseObj));
        this.handleFailures(ErrorCodes.FAILURE, ErrorTypes.FLOW_ERROR, ErrorMessages.SOME_UNKOWN_ERROR, true, this.aaErrorContext(responseObj));
        /*  log.warn("Please retry again");
       reject("RETRY"); */
      } else if (responseObj?.status === "BAD_REQUEST") {
        this.context?.fireJourneyEvents?.(ConductorEvent.AA_OTP_INVALID, {}, {}, this.aaErrorContext(responseObj));
        this.handleBadRequest(ErrorCodes.BAD_REQUEST, ErrorTypes.FLOW_ERROR, ErrorMessages.BAD_REQUEST, true, this.aaErrorContext(responseObj));
        /*  log.warn("Please retry again");
       reject("RETRY"); */
      } else if (responseObj?.status === "UNKNOWN_ERROR") {
        this.handleFailures(ErrorCodes.UNKNOWN, ErrorTypes.FLOW_ERROR, responseObj.message, true, this.aaErrorContext(responseObj));
      } else {
        // TODO: handle AA OTP verification error
        this.context?.fireJourneyEvents?.(ConductorEvent.AA_OTP_VERIFY_FAILED, {}, {}, this.aaErrorContext(responseObj));
        this.log.error("AA OTP verifification failed, with AA message?", err?.message);
      }
    } else if (err === ErrorMessages.SESSION_EXPIRED) {
      this.context.fireJourneyEvents(ConductorEvent.AA_OTP_VERIFY_FAILED);
      this.log.fatal("AA OTP verifification failed", err | err?.message);
      this.handleSessionError();
    }
  }
}